<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th>Email</th>
          <th>First Last name</th>
          <th>Country</th>
          <th>Phone</th>
          <th>Offer</th>
          <th>Updated at</th>
          <th>Created at</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in data" :key="index">
          <td>
            <span>
              <v-btn
                class="ml-0 pa-1"
                text
                x-small
                :to="`contacts/${item.uuid}`"
              >
                {{
                  item.email && item.email.address
                    ? item.email.address
                    : "without email"
                }}
              </v-btn>
              <v-icon
                v-if="item.email && item.email.is_valid"
                color="green"
                small
                >mdi-check</v-icon
              >

              <v-icon
                v-else-if="item.email"
                :color="item.email.is_valid === false ? 'red' : 'orange'"
                small
                >mdi-close</v-icon
              >
            </span>
          </td>
          <td>
            {{ item.first_name ? item.first_name : "" }}
            {{ item.last_name ? item.last_name : "" }}
          </td>
          <td>
            <country-flag
              v-if="item.country"
              :country="item.country"
              size="small"
            />
          </td>
          <td>
            <span v-if="item.phone && item.phone.number">
              {{ item.phone.number }}
              <v-icon v-if="item.phone.is_valid" color="green" small
                >mdi-check</v-icon
              >

              <v-icon
                v-else
                :color="item.phone.is_valid === false ? 'red' : 'orange'"
                small
                >mdi-close</v-icon
              >

              <v-icon
                v-if="
                  item.phone.has_watsapp === true ||
                  item.phone.has_watsapp === false
                "
                :color="item.phone.has_watsapp === false ? 'gray' : '#008000'"
                small
                >mdi-whatsapp</v-icon
              >
            </span>
          </td>
          <td>
            <span
              v-if="
                item.sources &&
                item.sources.length &&
                sortSources(item.sources) &&
                sortSources(item.sources).offer
              "
              :class="{
                'no-offer-name': !sortSources(item.sources).offer.name,
              }"
            >
              {{
                sortSources(item.sources).offer.name
                  ? sortSources(item.sources).offer.name
                  : sortSources(item.sources).offer.key
              }}
            </span>
          </td>
          <td>
            {{ formatDate(item.updated_at) }}
          </td>
          <td>
            {{ formatDate(item.created_at) }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import CountryFlag from "vue-country-flag";
import { renderDateTime } from "@/services/services";

export default {
  props: ["data"],
  components: {
    CountryFlag,
  },
  methods: {
    sortSources(sources) {
      let arr = JSON.parse(JSON.stringify(sources));
      // console.log(arr.sort((a, b) => new Date(b.date) - new Date(a.date)));
      let lastElement = arr.pop();
      return lastElement;
    },
    formatDate(date) {
      return renderDateTime(date);
    },
  },
};
</script>

<style lang="scss">
.no-offer-name {
  color: red;
  font-weight: 500;
}
</style>
