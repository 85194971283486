<template>
  <div>
    <GlobalLoader :is_loading="is_loading" />
    <v-container :fluid="true" v-if="rules">
      <SaveTemplateDialog />
      <v-card class="mb-3 contact-filter pa-3">
        <div class="d-flex align-center justify-space-between">
          <b>Filter</b>
          <v-autocomplete
            v-model="selectedTemplate"
            :items="templates_list"
            :loading="isLoadingTemplates"
            :search-input.sync="searchTemplates"
            @focus="() => onLoadTemplates_v2()"
            item-text="name"
            item-value="id"
            label="Template"
            placeholder="Search"
            clearable
            return-object
            solo
            dense
            hide-details
            style="max-width: 300px"
          ></v-autocomplete>
        </div>
        <div>
          <QueryBuilder ref="queryBuilder" />
          <div class="d-flex justify-space-between align-center">
            <div>
              <v-btn color="info" type="button" @click="onApply"> Apply </v-btn>
              <v-btn
                class="ml-4"
                color="info"
                outlined
                type="button"
                @click="onClear"
              >
                Clear
              </v-btn>
            </div>
            <div>
              <v-btn
                class="ml-2"
                color="primary"
                :disabled="has_elasticQuery"
                @click="onSaveTemplate"
              >
                {{
                  has_elasticQuery ? "Apply a filter first" : "Save as Template"
                }}
              </v-btn>
              <v-btn
                @click="onUpdateTemplate"
                v-if="selectedTemplate && !has_elasticQuery"
                class="ml-2"
                color="primary"
                outlined
                :disabled="has_elasticQuery"
              >
                {{
                  has_elasticQuery ? "Select template first" : "Update Template"
                }}
              </v-btn>
              <v-btn
                class="ml-2"
                color="primary"
                @click="toggleOpenCreateBroadcast"
                :disabled="has_elasticQuery"
              >
                {{
                  has_elasticQuery ? "Apply a filter first" : "Create Broadcast"
                }}
              </v-btn>
              <v-btn
                class="ml-2"
                color="primary"
                @click="toggleOpenExportBroadcast"
                :disabled="has_elasticQuery"
              >
                {{ has_elasticQuery ? "Apply a filter first" : "Export" }}
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    class="ma-2 white--text"
                    fab
                    small
                    @click="triggerImport"
                    :loading="isLoadingFile"
                  >
                    <v-icon dark> mdi-cloud-upload </v-icon>
                    <input
                      ref="fileInput"
                      accept="text/csv"
                      @change="onUploadCSV"
                      id="attach_csv"
                      style="display: none"
                      name="thumbnail"
                      type="file"
                    />
                  </v-btn>
                </template>
                <span>Upload CSV</span>
              </v-tooltip>

              <create-broadcast
                :is-open="isShowCreateBroadcast"
                :toggle-open-modal="toggleOpenCreateBroadcast"
                :filter_conditions="elasticQuery"
              />
              <ExportBroadcast
                :is-open="isShowExportBroadcast"
                :toggle-open-modal="toggleOpenExportBroadcast"
                :filter_conditions="elasticQuery"
              />
            </div>
          </div>
        </div>
      </v-card>
      <v-card class="mb-3 contact-filter pa-3">
        <div class="contacts-table__header">
          <div class="contacts-table__header-total">
            <b>Contacts</b>
            <div>
              <v-divider
                style="height: 20px; width: 7px; margin: 0 6px"
                inset
                vertical
              ></v-divider>
              <span style="font-size: 13px">Total</span>:
              <b>{{ contactsData ? contactsData.count : 0 }}</b>
              <span
                v-if="contactsData && contactsData.took"
                style="font-size: 12px; color: #b9b9b9"
              >
                <v-divider
                  style="height: 20px; width: 7px; margin: 0 6px"
                  inset
                  vertical
                ></v-divider
                >took: {{ contactsData.took }}ms
              </span>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
        <div>
          <div
            v-if="
              contactsData &&
              contactsData.results &&
              contactsData.results.length
            "
          >
            <ContactsTable :data="contactsData.results" />
          </div>
          <span
            v-else-if="!is_loading"
            class="d-flex align-center justify-center text-caption"
            >No data found</span
          >
        </div>
      </v-card>
    </v-container>
    <ChangeSCV />
  </div>
</template>

<script>
import Papa from "papaparse";
import ChangeSCV from "./components/ChangeCSV.vue";
import {
  loadListElastic,
  updateTemplate_v2,
  importCSV_v2,
  loadElasticRules,
} from "@/store/modules/contacts/api";
import QueryBuilder from "@/containers/QueryBuilder/QueryBuilder.vue";
import CreateBroadcast from "../Broadcasts_v2/CreateBroadcast_v2.vue";
import ExportBroadcast from "../Broadcasts_v2/ExportBroadcast.vue";
import GlobalLoader from "@/components/GlobalLoader.vue";
import ContactsTable from "./components/ContactsTable.vue";
import {
  objectFromQueryString,
  changeUrlQuery,
  requestStatus,
  isObjectEmpty,
} from "@/services/services";
import { mapActions, mapState } from "vuex";
import {
  LOAD_ELASTIC_RULES,
  LOAD_TEMPLATES_v2,
  LOAD_PATCHES,
  SET_ELASTIC_RULES,
} from "../../store/modules/contacts/types";
import SaveTemplateDialog from "./components/SaveTemplateDialog.vue";
export default {
  components: {
    QueryBuilder,
    CreateBroadcast,
    GlobalLoader,
    ContactsTable,
    SaveTemplateDialog,
    ChangeSCV,
    ExportBroadcast,
  },
  created() {
    this.is_loading = true;
    this.onLoadElasticPatches();
    loadElasticRules().then((response) => {
      this.is_loading = false;
      //   let queryFilter = objectFromQueryString();
      //   if (queryFilter.limit) this.filter.limit = queryFilter.limit;
      //   if (queryFilter.search) this.filter.search = queryFilter.search;
      //   if (queryFilter.offset) this.filter.offset = queryFilter.offset;
      this.setElasticRules(response.response.data).then(() => {
        setTimeout(() => {
          if (localStorage.getItem("filter_render_rules")) {
            window.getApp.$emit(
              "SET_RENDER_RULES",
              JSON.parse(localStorage.getItem("filter_render_rules"))
            );
          } else {
            this.getListData();
          }
        }, 100);
      });
    });
  },
  destroyed() {
    window.getApp.$off("ON_CHANGE_RULES");
    window.getApp.$off("GET_CONTACTS_DATA");
  },
  mounted() {
    window.getApp.$on("ON_CHANGE_RULES", () => {
      this.renderRules = null;
      this.elasticQuery = null;
      // localStorage.removeItem("filter_render_rules");
    });
    window.getApp.$on("GET_CONTACTS_DATA", () => {
      this.getListData();
    });
  },
  data() {
    return {
      contactsData: null,
      is_loading: false,
      isShowCreateBroadcast: false,
      isShowExportBroadcast: false,
      renderRules: null,
      elasticQuery: null,
      selectedTemplate: null,
      searchTemplates: "",
      isLoadingTemplates: false,
      isLoadingFile: false,
      filter: {
        limit: 100,
        offset: 0,
        search: "",
      },
    };
  },
  watch: {
    selectedTemplate(selectedTemplate) {
      localStorage.removeItem("filter_render_rules");
      window.getApp.$emit(
        "SET_RENDER_RULES",
        selectedTemplate && selectedTemplate.filter_conditions
          ? JSON.parse(JSON.stringify(selectedTemplate.filter_conditions))
          : null
      );
      this.renderRules = null;
      this.elasticQuery = null;
      localStorage.removeItem("filter_render_rules");
    },
    searchTemplates(newValue) {
      if (!this.selectedTemplate || newValue !== this.selectedTemplate.name) {
        this.onWaitChangeTemplates();
      }
    },
  },
  computed: {
    ...mapState({
      rules: (state) => state.contacts.elastic_rules,
      filterTemplates_v2: (state) => state.contacts.filterTemplates_v2,
    }),
    has_elasticQuery() {
      return isObjectEmpty(this.elasticQuery);
    },
    templates_list: function () {
      let results = [...this.filterTemplates_v2];
      if (this.selectedTemplate) {
        let default_item = results.filter(
          (item) => this.selectedTemplate.id === item.id
        );
        if (!default_item.length) {
          results.push(this.selectedTemplate);
        }
      }
      return results;
    },
  },
  methods: {
    ...mapActions({
      onLoadElasticPatches: `contacts/${LOAD_PATCHES}`,
      onLoadTemplates_v2: `contacts/${LOAD_TEMPLATES_v2}`,
      setElasticRules: `contacts/${SET_ELASTIC_RULES}`,
    }),
    triggerImport: function () {
      this.$refs.fileInput.click();
    },
    onUploadCSV: function (event) {
      this.isLoadingFile = true;
      // let input = event.target;
      // if (input.files && input.files[0]) {
      //   let file = input.files[0];
      //   let data = new File([file], encodeURIComponent(file.name), {
      //     type: file.type,
      //   });
      //   this.is_loading = true;
      //   importCSV_v2(data)
      //     .then((response) => {
      //       if (response.status === requestStatus.success) {
      //         this.$toaster.success("Uploaded");
      //       } else {
      //         this.$toaster.error(
      //           response.messages || "Oops, something went wrong."
      //         );
      //       }
      //     })
      //     .finally(() => {
      //       this.$refs.fileInput.value = "";
      //       this.is_loading = false;
      //     });
      // }

      let input = event.target;
      if (input.files && input.files[0]) {
        let file = input.files[0];
        // Papa.parse(file, {
        //   header: true,
        //   complete: (result) => {
        //     window.getApp.$emit("OPEN_CSV_CHANGE_DIALOG", {
        //       headers: result.meta.fields,
        //       data: result.data.map((item) => Object.values(item)),
        //     });
        //     this.$refs.fileInput.value = "";
        //     console.log(123);
        //     this.isLoadingFile = false;
        //   },
        // });
        let rowCount = 0;
        let headers = null;
        const data = [];

        Papa.parse(file, {
          header: true,
          step: (results, parser) => {
            if (rowCount < 15) {
              const row = results.data;
              if (rowCount === 0) {
                headers = results.meta.fields;
              }
              data.push(row);
              rowCount++;
            } else {
              parser.abort();
            }
          },
          complete: (result) => {
            window.getApp.$emit("OPEN_CSV_CHANGE_DIALOG", {
              headers: headers,
              data: data.map((item) => Object.values(item)),
              file: file,
            });
            this.$refs.fileInput.value = "";
            this.isLoadingFile = false;
          },
        });
        this.$refs.fileInput.value = "";
      }
    },
    onSaveTemplate() {
      let query = this.$refs.queryBuilder.getQuery();
      this.getRenderRules();
      if (query === undefined) {
        this.$toaster.error("Invalid query");
        return;
      }
      window.getApp.$emit("OPEN_SAVE_TEMPLATE_DIALOG", this.renderRules);
    },
    onUpdateTemplate() {
      let query = this.$refs.queryBuilder.getQuery();
      this.getRenderRules();
      if (query === undefined) {
        this.$toaster.error("Invalid query");
        return;
      }
      updateTemplate_v2({
        id: this.selectedTemplate.id,
        data: {
          filter_conditions: this.renderRules,
          name: this.selectedTemplate.name,
        },
      }).then((response) => {
        if (response.status === requestStatus.success) {
          this.$toaster.success("Template updated");
        } else {
          this.$toaster.error(
            response.messages || "Oops, something went wrong."
          );
        }
      });
    },
    toggleOpenCreateBroadcast() {
      this.isShowCreateBroadcast = !this.isShowCreateBroadcast;
    },
    toggleOpenExportBroadcast() {
      this.isShowExportBroadcast = !this.isShowExportBroadcast;
    },
    onApply() {
      this.filter.offset = 0;
      this.getListData();
    },
    onClear() {
      window.getApp.$emit("CLEAR_RENDER_RULES");
      this.onApply();
    },
    onWaitChangeTemplates() {
      if (
        this.searchTemplates &&
        (this.searchTemplates.length >= 2 || this.searchTemplates === "")
      ) {
        this.onLoadTemplates_v2(`search=${this.searchTemplates}`);
      }
      if (localStorage.getItem("filter_conditions")) {
        this.isChangedFilter = false;
      }
    },
    async getListData() {
      if (!this.$refs.queryBuilder) return;
      let query = this.$refs.queryBuilder.getQuery();
      this.getRenderRules();
      if (query === undefined) {
        this.$toaster.error("Enter the data for the query");
      } else {
        this.elasticQuery = JSON.parse(JSON.stringify(query));
        localStorage.setItem(
          "filter_render_rules",
          JSON.stringify(this.renderRules)
        );
        // changeUrlQuery(this.filter);
        this.is_loading = true;
        loadListElastic({
          filter_conditions: this.elasticQuery,
          // sort: ["-updated_at"],
          // ...this.filter,
        })
          .then((response) => {
            if (response.status === requestStatus.success) {
              this.contactsData = response.response.data;
            } else {
              this.$toaster.error(
                response.messages || "Oops, something went wrong."
              );
            }
          })
          .finally(() => {
            this.is_loading = false;
          });
      }
    },
    getRenderRules() {
      this.renderRules = JSON.parse(
        JSON.stringify(this.$refs.queryBuilder.getRenderRules())
      );
    },
  },
};
</script>

<style lang="scss">
.contacts-table {
  &__header {
    display: flex;
    align-items: center;
    &-total {
      display: flex;
      align-items: center;
    }
  }
}
// .loader {
//   width: 20px;
//   height: 20px;
//   border-radius: 50%;
//   display: inline-block;
//   border-top: 3px solid #fff;
//   border-right: 3px solid transparent;
//   box-sizing: border-box;
//   animation: rotation 1s linear infinite;
// }

// @keyframes rotation {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }
</style>
